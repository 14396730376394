import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { ContactForm } from '/src/components/contact'

const ContactPage = () => {
  return (
    <Layout hero={<HeroImage title="Terms & Conditions" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <p>
                This web site and its contents ("Web Site") are owned and operated by Metalcorp Steel P/L ABN 13 002 581 213 of 16-24 Westall Rd, Clayton South Victoria, Australia 3169 ("Metalcorp Steel"). The use of this Web Site is governed by the following terms and conditions, as amended from time to time (the "Terms and Conditions").
              </p>
              <p>
                By using this Web Site, you acknowledge that you have read and understood these Terms and Conditions and agree to be bound by them. If you do not agree with these Terms and Conditions (including any amendments to them) you should immediately cease using the Web Site. The Web Site may be updated from time to time, including these Terms and Conditions. Metalcorp Steel encourages you to check this document regularly. By continuing to use this Web Site after these Terms and Conditions have been updated, you acknowledge that you have read and understood the updated Terms and Conditions and agree to be bound by them.
              </p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Intellectual property and restrictions on use of content
                </h3>
              </div>
              <p>
                The Web Site and the materials on this Web Site are subject to copyright. Unless otherwise indicated, the copyright is owned by Metalcorp Steel or a related company, which reserve all rights. Except for the direct purpose of viewing, printing, accessing or interacting with the Web Site for your own personal use or as otherwise indicated on the Web Site or these Terms and Conditions, you must not reproduce, modify, communicate to the public, adapt, transfer, distribute or store any of the contents of the Web Site, or incorporate any part of the Web Site into another web site without Metalcorp Steel's consent.
              </p>
              <p>
                The Web Site also includes registered and unregistered trademarks, which are owned by Metalcorp Steel or a related company. Except to the extent that it is expressly indicated to the contrary, your access to or use of this Web Site does not constitute or create a licence or any other right to use the name, trade dress, trademarks, copyrights or any other intellectual property which appears on the Web Site. Metalcorp Steel reserves the right to revise the content of, amend links from or withdraw access to the Web Site at any time without notice and without liability to you or any other person.
              </p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Links to Third Party Sites and Third-Party Advertisements
                </h3>
              </div>
              <p>Metalcorp Steel assumes no responsibility and accepts no liability for the condition or content of third party web sites which may be linked to or accessed from the Web Site. Except as Metalcorp Steel may specifically direct otherwise, Metalcorp Steel does not authorise the content of those third-party web sites. The Web Site may also contain third party advertisements which contain embedded hyper-links to web sites operated by third parties. The placement of third party advertisements on this Web Site does not necessarily constitute the recommendation or endorsement of Metalcorp Steel for such goods or services. The third-party advertiser is solely responsible to you for any representations or offers made by it, and for any goods or services which you agree to purchase from those third parties.</p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Financial Information and advice
                </h3>
              </div>
              <p>
                The information on this Web Site provides general information only and is not intended as investment advice and must not be relied upon as such. You should obtain independent professional advice and make further independent enquires before making any investment decisions or taking any further action based on the information contained on this Web Site. Without implying any licence to do so, if you pass on any information contained in this Web Site to any other person, you should pass on this disclaimer also and draw it to their attention. Metalcorp Steel is not offering and must not be construed as offering, any securities or other financial products under or through this Web Site and this Web Site is not an invitation to you to take up securities or other financial products. Metalcorp Steel does not provide, and must not be construed as providing, recommendations in relation to any securities or other financial products.
              </p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Software and other digital materials available for download
                </h3>
              </div>
              <p>
                From time to time certain software and related digital materials may be expressly made available by Metalcorp Steel for downloading from the Web Site. Unless those software or materials are expressed to be pursuant to specific conditions (in which case those conditions will apply), Metalcorp Steel grants to you a personal, non-transferable, and non-exclusive licence to use the relevant software or materials for your own private use, provided that you do not copy, communicate to the public, modify, reverse engineer, or otherwise transfer or purport to transfer any right in them, and provided also that you ensure that all copies of such software and materials retain any copyright or other intellectual property notices. Except as otherwise provided for in the terms of any express licence agreement, Metalcorp Steel gives no warranties (either express or implied) as to the completeness, accuracy or reliability of such software or materials, and they are downloaded and used by you entirely at your own risk.
              </p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Content and other information provided by you and others
                </h3>
              </div>
              <p>
                Certain parts of the Web Site may allow you to post messages or otherwise submit materials for publication on the Web Site. It is a condition of your use of, and continued access to, the Web Site that you do not restrict of inhibit any other user from using or enjoying the Web Site (or part of it) and do not post any material which is obscene, offensive, pornographic, defamatory, racist, discriminatory, in breach of third party rights (including intellectual property rights) or otherwise in breach of the law.
              </p>
              <p>
                Metalcorp Steel may monitor or review the contents of messages and materials posted on the Web Site and reserves the right to edit, refuse to post, or remove any message and materials from the Web Site as it sees fit and without liability. However, Metalcorp Steel is under no obligation to perform these activities and does not warrant that it will do so. The information and opinions expressed by users of the Web Site are those of the relevant individuals who post them, and Metalcorp Steel is not responsible for them.
              </p>
              <p>
                You grant to Metalcorp Steel a non-exclusive perpetual royalty free licence to copy, adapt, communicate to the public and otherwise use all or any part of the materials, information, ideas, concepts, know-how or techniques provided directly or indirectly by you through the Web Site or your use of the Web Site (including without limitation information gained from the fact that you have accessed the Web Site and information, goods or services you have sought from the Web Site (“Information”). You warrant that you have the ability to grant such rights to Metalcorp Steel and agree to indemnify Metalcorp Steel if any third party takes action against Metalcorp Steel in relation to Information you provide. All Information and other materials received from you shall be deemed to be non-confidential.
              </p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Personal information
                </h3>
              </div>
              <p>
                Metalcorp Steel may collect, use and disclose personal information about you obtained by Metalcorp Steel from your use of the Web Site. Metalcorp Steel's management of this information is governed by the Privacy Policy.
              </p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Competitions and promotions
                </h3>
              </div>
              <p>
                Certain parts of the Web Site may contain competitions or promotions from Metalcorp Steel or third parties. The terms and conditions for those competitions and promotions will be specified on the relevant part of the Web Site from time to time. By entering or participating in the relevant competition or promotion you agree to be bound by the relevant terms and conditions. Notwithstanding any specific terms and conditions, Metalcorp Steel specifically retains the rights, at any time and without notice, to remove, alter or add to competitions or promotions on the Web Site without any liability to you. All competitions and promotions are only open to persons located in Australia who are over 18 years of age, and prizes will not be awarded to people who do not meet this requirement at the time of entry.
              </p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Disclaimer and Limitation of Liability
                </h3>
              </div>
              <p>
                The Web Site and its entire contents are provided on an "as is" basis. Metalcorp Steel, related companies of Metalcorp Steel, and their directors, officers, employees, agents and contractors give or make no representation or warranty (either express or implied) as to the completeness, accuracy, reliability or availability of the Web Site or its contents nor do they accept any responsibility arising in any way (including from negligence) for errors in, or omissions from, the contents of this Web Site. You should be aware that information current to a particular time or date as placed on the Web Site does not mean that more recent information of the relevant type does not exist, or is not available through other means, or that circumstances have not changed, such that the information does not reflect the state of affairs at any subsequent time. Further, for various reasons, substantial delays may occur before particular information on this Web Site is updated.
              </p>
              <p>
                Metalcorp Steel, related companies of Metalcorp Steel, and their directors, officers, employees, agents and contractors are not liable for any loss, damage, claim, expense, cost (including legal costs) or liability arising from your use or non-use of the Web Site (including the downloading of any materials or the purchase or attempted purchase of any products), or your reliance upon information contained upon it (whether arising from Metalcorp Steel or any other person's negligence or otherwise). Where any Act of Parliament implies any term, condition, representation or warranty into the contents of this Web Site or your use of or access to the Web Site, and that Act prohibits exclusion of that term, condition, representation or warranty, then that term, condition, representation or warranty is included. If so, and to the extent permitted by the Act, Metalcorp Steel's liability for any breach of such term, condition, representation or warranty is limited to the supplying of the relevant good or services again, as Metalcorp Steel may choose.
              </p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Indemnity
                </h3>
              </div>
              <p>
                You agree to indemnify and keep indemnified Metalcorp Steel, related companies of Metalcorp Steel and their directors, officers, employees, agents and contractors in respect of any claim, liability, loss, damage, cost (including legal cost) or expense which Metalcorp Steel, related companies of Metalcorp Steel and their directors, officers, employees, agents and contractors may suffer or incur as a direct or indirect result of your wilful or negligent act or omission with respect to the Web Site (or any part of it) or your breach of these Terms and Conditions.
              </p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Termination
                </h3>
              </div>
              <p>
              Metalcorp Steel reserves the right to revise the content of, amend links from, or withdraw access to the Web Site at any time without notice and liability to you. Your membership of the Web Site, your ability to participate in all or certain parts of the Web Site, or your ability to access the Web Site may be terminated at any time without notice, and without any liability to you. Any indemnities given by you and limitations on Metalcorp Steel's liability will survive such termination.
              </p>
              <div className="title-container mb-3">
                <h3 className="title">
                  Miscellaneous
                </h3>
              </div>
              <p>
                A right relating to these Terms and Conditions may only be waived by Metalcorp Steel in writing. These Terms and Conditions are governed by and construed in accordance with the laws of Victoria, Australia. By using the Web Site, you irrevocably and unconditionally submit to the non-exclusive jurisdiction of the courts of Victoria. If any provision of these Terms and Conditions are found to be invalid or unenforceable by a Court of Law, such invalidity or unenforceability will not affect the remaining provisions, which will continue in full force and effect. Metalcorp Steel's failure to exercise or enforce any rights or any provision of these Terms and Conditions will not constitute a waiver of such right or provision unless acknowledged and agreed to by Metalcorp Steel in writing.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default ContactPage

export const Head = () => {
  return (
    <>
      <title>Terms & Conditions | Metalcorp</title>
      <meta name="description" content="Metalcorp is an Australian steel distribution business that is into everything in steel. With huge stock holdings, our products can be cut to length and delivered to site." />
      <meta name="og:description" property="og:description" content="Seamless Online Shopping for Quality Steel and Rural Products. Metalcorp is your rural brand for the land." />
      <meta property="og:image" content="https://metalcorp-static-assets.s3.ap-southeast-2.amazonaws.com/opengraph.png" />
    </>
  )
}

